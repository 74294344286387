html {
  font-size: 100%;
}
body {
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  line-height: 1.5;
}
button:disabled {
  opacity: 0.5;
}
.ant-badge-count,
.Console__badge {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
  border-radius: 50%;
}
.Console__badge_warning > .ant-badge-count {
  background-color: #ff4d4f !important;
  color: #fff !important;
}
.Console__badge {
  margin-left: 5px;
}
.Console-warning-log {
  color: #ff4d4f;
}
.DownloadForm__hint {
  cursor: pointer;
  font-size: 1.1rem;
  margin-left: 10px;
}
.DocumentsList__files-table {
  margin-bottom: 1rem;
}
.Login {
  margin-top: 10%;
}
.Login__title {
  margin: 0 0 1.5rem 0;
  text-align: center;
}
.Login__button-row {
  text-align: center;
}
.Login__login-button {
  width: 50%;
}
.Header__userInfo {
  color: #fff;
  float: right;
}
.Header__exit-link {
  margin: 0 0 0 15px;
}
@media screen and (max-width: 1024px) {
.Header {
  padding: 0 10px;
}
}
.Content {
  padding: 25px 50px 0 50px;
@media screen and (max-width: 1024px) {
}
}
.Content__container {
  background-color: #fff;
  padding: 24px;
  min-height: 280px;
}
@media screen and (max-width: 1024px) {
.Content {
  padding: 0;
}
.Content__container {
  padding: 15px 10px;
}
}
.Footer {
  text-align: center;
}
.Form__container {
  margin: 1rem 0;
}
.Form__numberInput {
  margin-left: 10px;
}
.Users__loader {
  text-align: center;
  margin: 2em 0;
}
.Users__control-buttons {
  font-size: 1.2em;
}
.Users__add-button {
  margin-left: 15px;
}
.Users__approved {
  color: #389e0d;
  font-size: 1.2rem;
}
.Users__not-approved {
  color: #d4b106;
  font-size: 1.2rem;
}
.Users__search {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.Users__selected-text {
  background-color: #ffa328;
}
.Users__table-cell:hover {
  cursor: pointer;
}

